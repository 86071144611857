import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: 'app', loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: '', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'otp', loadChildren: './otp/otp.module#OtpPageModule' },
  { path: 'select-city', loadChildren: './select-city/select-city.module#SelectCityPageModule' },
  { path: 'choose-functionality', loadChildren: './choose-functionality/choose-functionality.module#ChooseFunctionalityPageModule' },
  { path: 'service-detail', loadChildren: './service-detail/service-detail.module#ServiceDetailPageModule' },
  { path: 'cart-detail', loadChildren: './cart-detail/cart-detail.module#CartDetailPageModule' },
  { path: 'select-location', loadChildren: './select-location/select-location.module#SelectLocationPageModule' },
  { path: 'select-datetime', loadChildren: './select-datetime/select-datetime.module#SelectDatetimePageModule' },
  { path: 'content-page', loadChildren: './content-page/content-page.module#ContentPagePageModule' },
  { path: 'wallet', loadChildren: './wallet/wallet.module#WalletPageModule' },
  { path: 'address_multiple', loadChildren: './address-multiple/address-multiple.module#AddressMultiplePageModule' },
  { path: 'success-page', loadChildren: './success-page/success-page.module#SuccessPagePageModule' },
  { path: 'select-locationsearch', loadChildren: './select-locationsearch/select-locationsearch.module#SelectLocationsearchPageModule' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
