import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatCheckboxModule } from '@angular/material';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { AngularFireModule } from 'angularfire2';
import { AngularFireAuthModule } from 'angularfire2/auth';

import { NgMatSearchBarModule } from 'ng-mat-search-bar';
import { Geolocation } from '@ionic-native/geolocation/ngx';


const firebaseConfig = {
  apiKey: "AIzaSyC5HvF-RmkLkxoXUT7N9natwQYhNngYkQI",
  authDomain: "clapappucclone.firebaseapp.com",
  databaseURL: "https://clapappucclone.firebaseio.com",
  projectId: "clapappucclone",
  storageBucket: "clapappucclone.appspot.com",
  messagingSenderId: "5502792194"
};

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,NgMatSearchBarModule, IonicModule.forRoot(), AppRoutingModule, AngularFireModule.initializeApp(firebaseConfig), AngularFireAuthModule,
  BrowserAnimationsModule, MatButtonModule, MatCheckboxModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    SplashScreen,Geolocation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
